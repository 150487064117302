/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { FC, useState } from "react"
import { Global, css } from "@emotion/core"
import emotionReset from "emotion-reset"
import { Header } from "./Header"
import { Footer } from "./Footer"
import theme from "../../gatsby-plugin-theme-ui"
import { IconContext } from "react-icons"
import { HeaderInfo } from "../HeaderInfo"

// export const ActionInfoQuery = graphql`
//   query metaDataInfo {
//     metaData: allLandingPageJson {
//       nodes {
//         hero_description
//       }
//     }
//   }
// `

interface LayoutProps {}

const globalStyles = css`
    ${emotionReset}
    html,
  body {
        font-family: ${theme.fonts.content};
        color: ${theme.colors.dark};
        font-size: ${theme.fontSizes[2] + "px"};
    }

    a {
        text-decoration: none;
        display: inline-block;
    }

    * {
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"],
    textarea {
        /* background: inherit; */
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        inset: none;
        /* border: none; */
        /* color: inherit; */
        /* font-weight: inherit; */
        /* -webkit-tap-highlight-color: transparent; */
        &:focus {
            /* color: var(--third9); */
        }
        resize: none !important;
    }
`

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Layout: FC<LayoutProps> = props => {
    // const data = useStaticQuery(ActionInfoQuery) as MetaDataInfoQuery
    // const node = data.metaData.nodes[0]!

    return (
        <React.Fragment>
            <HeaderInfo></HeaderInfo>
            <IconContext.Provider
                value={{
                    style: {
                        verticalAlign: "middle",
                        color: theme.colors.dark,
                    },
                }}
            >
                <div
                    sx={{
                        position: "relative",
                        px: theme => theme.pagePX,
                        py: theme => theme.pagePY,
                        paddingBottom: theme => ["auto", theme.footerHeight],
                        minHeight: "100vh",
                        maxWidth: theme => theme.pageMaxWidth,
                        margin: "auto",
                    }}
                >
                    <Global styles={globalStyles}></Global>

                    <Header></Header>
                    {props.children}
                    <Footer></Footer>
                </div>
            </IconContext.Provider>
        </React.Fragment>
    )
}
export default Layout
