import React, { FC } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { SiteMetadataQuery } from "../../graphql-types"

export const staticQuery = graphql`
    query siteMetadata {
        site {
            siteMetadata {
                title
                description
                keywords
            }
        }
    }
`

interface HeaderInfoProps {
    appendTitle?: string
    description?: string
    appendKeywords?: string
}

const HeaderInfo: FC<HeaderInfoProps> = props => {
    const data = useStaticQuery(staticQuery) as SiteMetadataQuery

    return (
        <Helmet
            title={`${data.site!.siteMetadata!.title} ${
                props.appendTitle ? `| ${props.appendTitle}` : ""
            }`}
            meta={[
                {
                    name: "description",
                    content:
                        // node.hero_description +
                        data.site!.siteMetadata!.description ||
                        props.description ||
                        "",
                },
                {
                    name: "keywords",
                    content:
                        data.site!.siteMetadata!.keywords +
                            (props.appendKeywords || "") || "",
                },
            ]}
        >
            <html lang="en" />
        </Helmet>
    )
}

export { HeaderInfo }
