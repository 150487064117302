// import "typeface-amatic-sc"
// import "typeface-josefin-sans"

import "typeface-roboto"
import "typeface-roboto-slab"
import "typeface-clear-sans"

const colors = {
    grays: ["#F9F9F8", "#C9C3B9", "#433E37"],
    primaryGradient: [
        "#F9F9FA", // 0
        "#EDECF0", //
        "#8C859E", // 2
        "#706785", //
        "#413C4E", // 4
        "#2E2B37", // 5
    ],
    secondaryGradient: ["#5A6484", "#40485E", "#2d3242"], // [ , // , //]
}

const breakpoints = ["600px", "1200px"]

const transitions = [".1s", ".3s", ".9s"]

const barHeight = ["3.5rem", "4rem"]

const pagePX = [3, 5, 5]
const pagePY = [3, 4, 5]

const base = {
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    fonts: {
        heading: "Roboto Slab , system-ui, sans-serif",
        content: "Avenir Next, system-ui, serif",
    },
    fontSizes: [10, 12, 16, 21, 24, 28, 32, 40, 48, 64, 96],
    fontWeights: {
        light: 300,
        normal: 400,
        medium: 500,
        bold: 700,
    },
    lineHeights: {
        body: 1.5,
        heading: 1.25,
    },
    colors: {
        ...colors,
        text: colors.primaryGradient[4],
        background: colors.grays[0],
        primary: "#89728C",
        secondary: colors.secondaryGradient[1],
        muted: colors.primaryGradient[0],
        lighter: colors.primaryGradient[1], // custom
        light: colors.primaryGradient[2],
        dark: colors.primaryGradient[3],
        darker: colors.primaryGradient[4],
        darkest: colors.primaryGradient[5],
        secondaryLight: colors.secondaryGradient[0],
        secondaryDark: colors.secondaryGradient[2],
        // can use 'modes'
    },
    letterSpacings: {
        body: "normal",
        caps: "0.2em",
    },
    radii: [1, 3, 5, "100%"],
    breakpoints,
    transitions,
    mediaQueries: {
        // small: `@media screen and (min-width: ${breakpoints[0]})`,
        medium: `@media screen and (min-width: ${breakpoints[0]})`,
        large: `@media screen and (min-width: ${breakpoints[1]})`,
    },
    barHeight,
    pagePX,
    pagePY,
    pageMaxWidth: 1800,
}

const variants = {
    text: {
        heading: {
            fontFamily: "heading",
            lineHeight: "heading",
            marginBottom: 1,
            fontWeight: "medium",
            fontSize: [6, 7],
            color: "darker",
        },
        copy: {
            fontFamily: "content",
            lineHeight: "body",
            fontWeight: "light",
            color: "text",
            fontSize: [2, 3],
        },
        label: {
            fontFamily: "heading",
            fontSize: [2],
            fontWeight: "bold",
            color: "secondary",
            display: "inline-block",
            marginBottom: 1,
        },
    },
    link: {
        normal: {
            color: "dark",
            //   transition: 1,
            cursor: "pointer",
            ":hover": {
                color: "darker",
                textDecoration: "underline",
            },
        },
        nav: {
            color: "darker",
            px: [2, 3],
            transition: 1,
            cursor: "pointer",
            ":hover": {
                color: "darkest",
                textDecoration: "underline",
            },
        },
    },
    fixedFullScreen: {
        height: "100%",
        width: "100%",
        position: "fixed",
        top: "0",
        left: "0",
    },
    icons: {
        clickableMenu: {
            display: "inline-block",
            color: "darker",
            padding: 2,
            cursor: "pointer",
            borderRadius: 2,
            // ":hover": {
            //   bg: "muted",
            // },
        },
    },
    barHeight,
    footerHeight: "3rem",
    // footerLineHeight: ["1.25rem", "1.5rem"],
    layouts: {
        header: {
            height: barHeight,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
        },
        footer: {},
    },
    absoluteCenter: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translateX(-50%) translateY(-50%)",
    },
}

export const theme = {
    ...base,
    ...variants,
}

export default theme
