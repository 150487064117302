/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { FC, useState } from "react"
import { graphql, Link } from "gatsby"
import logo from "../../../static/images/logo.svg"
import { FiMenu } from "react-icons/fi"
import { FiX } from "react-icons/fi"

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
    return (
        <div
            sx={{
                variant: "layouts.header",
                // justifyContent: ["center", "left", "left"],
                width: "100%",
            }}
        >
            <div
                sx={
                    {
                        // height: theme => theme.barHeight,
                    }
                }
            >
                <Link
                    sx={{
                        height: ["100%"],
                        width: ["100%"],
                        display: "flex",
                        alignItems: "center",
                    }}
                    to="/"
                >
                    <img
                        sx={{ width: "100%" }}
                        alt="Chinomatic logo"
                        src={logo}
                    ></img>
                </Link>
            </div>
        </div>
    )
}
export { Header }

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "blog/avatars/kyle-mathews.jpeg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 250) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
