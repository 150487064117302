/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { FC } from "react"
import { Link } from "gatsby"

interface FooterProps {}

const Footer: FC<FooterProps> = props => {
    return (
        <div
            sx={{
                variant: "layouts.header",
                height: theme => ["auto", theme.footerHeight],

                position: ["relative", "absolute"],
                bottom: 0,
                left: 0,
                display: ["block", "flex", "flex"],
                // marginBottom: ["1rem", "0"],
                textAlign: ["right", "left"],
                justifyContent: "space-between",
                alignItems: "center",
                px: theme => [0, theme.pagePX],
                marginTop: 3,
                // px: [3],
            }}
        >
            <div sx={{ lineHeight: "1.5rem" }}>© 2019 Edwin Gomez</div>
            <div sx={{ textAlign: "right", lineHeight: "1.5rem" }}>
                <Link
                    sx={{
                        variant: "link.nav",
                        px: [0, 2],
                        marginLeft: [3, 0],
                    }}
                    to="/frequently-asked-questions"
                >
                    FAQ
                </Link>
                <Link
                    sx={{
                        variant: "link.nav",
                        px: [0, 2],
                        marginLeft: [3, 0],
                    }}
                    to="/terms-of-serivce"
                >
                    Terminos de Servicio
                </Link>
                <Link
                    sx={{
                        variant: "link.nav",
                        px: [0, 2],
                        marginLeft: [3, 0],
                    }}
                    to="/privacy-policy"
                >
                    Polîtica de Privacidad
                </Link>
            </div>
        </div>
    )
}
export { Footer }
